var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Progress")]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/vue/docs/components/progress",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("CProgress", {
                attrs: {
                  value: _vm.counter,
                  max: _vm.max,
                  "show-percentage": "",
                  animated: ""
                }
              }),
              _c(
                "CProgress",
                {
                  staticClass: "mt-1",
                  attrs: { max: _vm.max, "show-value": "" }
                },
                [
                  _c("CProgressBar", {
                    attrs: {
                      value: _vm.counter * (6 / 10),
                      color: "gradient-success"
                    }
                  }),
                  _c("CProgressBar", {
                    attrs: {
                      value: _vm.counter * (2.5 / 10),
                      color: "gradient-warning"
                    }
                  }),
                  _c("CProgressBar", {
                    attrs: {
                      value: _vm.counter * (1.5 / 10),
                      color: "gradient-danger"
                    }
                  })
                ],
                1
              ),
              _c(
                "CButton",
                {
                  staticClass: "mt-4",
                  attrs: { color: "secondary" },
                  on: { click: _vm.clicked }
                },
                [_vm._v(" Click me to animate progress bars ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("labels")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("h6", [_vm._v("No label")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: { value: _vm.value, max: _vm.max2 }
              }),
              _c("h6", [_vm._v("Value label")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: { value: _vm.value, max: _vm.max2, "show-value": "" }
              }),
              _c("h6", [_vm._v("Progress label")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: {
                  value: _vm.value,
                  max: _vm.max2,
                  "show-percentage": ""
                }
              }),
              _c("h6", [_vm._v("Value label with precision")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: {
                  value: _vm.value,
                  max: _vm.max2,
                  precision: 2,
                  "show-value": ""
                }
              }),
              _c("h6", [_vm._v("Progress label with precision")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: {
                  value: _vm.value,
                  max: _vm.max2,
                  precision: 2,
                  "show-percentage": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("width")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("h6", [_vm._v("Default width")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: { value: _vm.value3 }
              }),
              _c("h6", [_vm._v("Custom widths")]),
              _c("CProgress", {
                staticClass: "w-75 mb-2",
                attrs: { value: _vm.value3 }
              }),
              _c("CProgress", {
                staticClass: "w-50 mb-2",
                attrs: { value: _vm.value3 }
              }),
              _c("CProgress", {
                staticClass: "w-25",
                attrs: { value: _vm.value3 }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("height")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("h6", [_vm._v("Default height")]),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: { value: _vm.value3, "show-percentage": "" }
              }),
              _c("h6", [_vm._v("Custom heights")]),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: {
                  height: "2rem",
                  value: _vm.value3,
                  "show-percentage": ""
                }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: {
                  height: "20px",
                  value: _vm.value3,
                  "show-percentage": ""
                }
              }),
              _c("CProgress", { attrs: { height: "2px", value: _vm.value3 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("colors")])
            ],
            1
          ),
          _c(
            "CCardBody",
            _vm._l(_vm.bars, function(bar, index) {
              return _c("div", { key: index, staticClass: "row mb-1" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _vm._v(_vm._s(bar.color) + ":")
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-10 pt-1" },
                  [
                    _c("CProgress", {
                      key: bar.color,
                      attrs: { value: bar.value, color: bar.color }
                    })
                  ],
                  1
                )
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("striped")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: { value: 25, color: "success", striped: _vm.striped }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: { value: 50, color: "info", striped: _vm.striped }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: { value: 75, color: "warning", striped: _vm.striped }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: { value: 100, color: "danger", striped: _vm.striped }
              }),
              _c(
                "CButton",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function($event) {
                      _vm.striped = !_vm.striped
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.striped ? "Remove" : "Add") + " Striped "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("animated")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: {
                  value: 25,
                  color: "success",
                  striped: "",
                  animated: _vm.animate
                }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: {
                  value: 50,
                  color: "info",
                  striped: "",
                  animated: _vm.animate
                }
              }),
              _c("CProgress", {
                staticClass: "mb-2",
                attrs: {
                  value: 75,
                  color: "warning",
                  striped: "",
                  animated: _vm.animate
                }
              }),
              _c("CProgress", {
                staticClass: "mb-3",
                attrs: { value: 100, color: "danger", animated: _vm.animate }
              }),
              _c(
                "CButton",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function($event) {
                      _vm.animate = !_vm.animate
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.animate ? "Stop" : "Start") + " Animation "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Progress ")]),
              _c("small", [_vm._v("multiple bars")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CProgress",
                { staticClass: "mb-3", attrs: { max: _vm.max3 } },
                [
                  _c("CProgressBar", {
                    attrs: { color: "gradient-primary", value: _vm.values[0] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-success", value: _vm.values[1] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-info", value: _vm.values[2] }
                  })
                ],
                1
              ),
              _c(
                "CProgress",
                {
                  staticClass: "mb-3",
                  attrs: { "show-percentage": "", max: _vm.max3 }
                },
                [
                  _c("CProgressBar", {
                    attrs: { color: "gradient-primary", value: _vm.values[0] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-success", value: _vm.values[1] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-info", value: _vm.values[2] }
                  })
                ],
                1
              ),
              _c(
                "CProgress",
                {
                  staticClass: "mb-3",
                  attrs: { "show-value": "", striped: "", max: _vm.max3 }
                },
                [
                  _c("CProgressBar", {
                    attrs: { color: "gradient-primary", value: _vm.values[0] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-success", value: _vm.values[1] }
                  }),
                  _c("CProgressBar", {
                    attrs: { color: "gradient-info", value: _vm.values[2] }
                  })
                ],
                1
              ),
              _c(
                "CProgress",
                { staticClass: "mb-3", attrs: { max: _vm.max3 } },
                [
                  _c("CProgressBar", {
                    attrs: {
                      color: "gradient-primary",
                      value: _vm.values[0],
                      "show-percentage": ""
                    }
                  }),
                  _c("CProgressBar", {
                    attrs: {
                      color: "success",
                      value: _vm.values[1],
                      animated: "",
                      "show-percentage": ""
                    }
                  }),
                  _c("CProgressBar", {
                    attrs: {
                      color: "gradient-info",
                      value: _vm.values[2],
                      striped: "",
                      "show-percentage": ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }